import React from 'react'
import { Col, Row } from 'react-grid-system'
import { Book, Download, FileText, Lock } from 'react-feather'
import { linkToReleaseNotes, linkToWhatsNew, linkToDownloads } from '../../utils/links'
import ReactPlayer from 'react-player'
import playerThumbnail from '../../images/player-thumbnail.svg'
import clsx from 'clsx'
import styled from 'styled-components'

const Style = styled.div`

  ul {
    list-style-type: none;
    margin: 0;

    li {
      margin: 0 0 12px 0;
      display: flex;
      align-items: center;

      svg {
        margin-right: .5rem;
      }
    }
  }

  .player-wrapper {
    max-width: 600px;

    > div {
      position: relative;
      padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
      width: 100%;
      max-width: 100%;
      border-radius: 6px;
      overflow: hidden;

      > div {
        position: absolute;
        top: 0;
        left: 0;

        &.disabled {
          background-color: #bcbcbc;
          height: 100%;
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;

          svg {
            color: #d2d2d2;
            width: 33%;
            height: 33%;
          }
        }
      }
    }
  }

  h3 {
    margin-top: 24px !important;
  }

  .webinar-download {
    margin-top: 1rem;
  }

  .disabled {
    color: #bcbcbc;
  }

`

const playerConfig = {
  file: {
    attributes: {
      controls: true,
      autoplay: true
    }
  }
}

const WhatsNewRelease = ({ node, isLoggedIn }) => {
  return (
    <Style>
      <Row>
        <Col xs={12}>
          <p>
            A new distribution of CoreMedia Content Cloud has been released.
          </p>
          <ul>
            {node.aep.whats_new_available && ((node.aep.version.includes('.') && node.amp.version === "0") || (!node.aep.version.includes('.') && node.amp.version === "1")) && (
              <li>
                <Book />
                <a href={linkToWhatsNew(node.major, node.aep.version, node.amp.version)} target={'_blank'} rel="noreferrer">Download What's New (PDF)</a>
              </li>
            )}
            <li >
              <FileText />
              <a href={linkToReleaseNotes(node.major, node.aep.version, node.amp.version)}>Full Release Notes</a>
            </li>
            <li>
              <Download />
              <a href={linkToDownloads(node.major, `${node.aep.version}.${node.amp.version}`)}>
                Download Resources
              </a>
            </li>
          </ul>
        </Col>
        <Col xs={12} md={6}>
            {(node.aep.release_webinar_recording  && node.amp.version === "1") ? (
            <>
              <h3>Webinar - For community members</h3>
              <div className="player-wrapper">
                <div>
                  {isLoggedIn && (
                    <ReactPlayer
                      playing={true}
                      width={'100%'}
                      height={'100%'}
                      url={node.aep.release_webinar_recording}
                      config={playerConfig}
                      controls={true}
                      light={playerThumbnail}/>
                  )}
                  {!isLoggedIn && (
                    <div className={'disabled'}><Lock /></div>
                  )}
                </div>
              </div>
              {node.aep.release_webinar_slides && (
                <div className={clsx('webinar-download', { disabled: !isLoggedIn })}>
                  {isLoggedIn ? <a href={node.aep.release_webinar_slides} target={'_blank'} rel={'noreferrer'}>Download Slide Deck</a> : 'Please login to access CoreMedia Community content.'}
                </div>
              )}
            </>
          ) : (
            <div>
            </div>
          )}
          {(!node.aep.release_webinar_recording && node.amp.version === "1") ? (
            <div>
              Webinar is not available yet.
            </div>
          ) : (<div></div>)
          }
        </Col>
      </Row>
    </Style>
  )
}

export default WhatsNewRelease
