import React from "react";
import styled from "styled-components";

const Select = styled.select`
  padding: 10px;
  border: 1px solid #000;
  background-color: #fff;
  color: #000;
  font-size: 16px;
  font-family: 'DM Sans', sans-serif;
  cursor: pointer;
  border-style: none none solid;

  &:focus {
    outline: none;
  }
`;
const Dropdown = ({ options, value, changeFunction}) => {
  console.log("Bin in DropDown Komponente mit Value:" + value)
  return (
    <Select value={value} onChange={changeFunction}>
      {options.map((option, index) => (
        <optgroup label={option.label} key={index}>
          {option.values.map((value, idx) => (
            <option value={value} key={idx}>
              {value}
            </option>
          ))}
        </optgroup>
      ))}
    </Select>
  );
};

export default Dropdown;
