import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import PropTypes from "prop-types";
import Dropdown from '../Dropdown';


const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 400px;
  //border: 1px solid #ccc;
  padding: 20px;
  padding-left: 0px;
  border-radius: 5px;

  h4 {
    margin-bottom:  0rem;
  }
`;



const Row = styled.div`
  display: flex;
  gap: 10px;
`;


const Button = styled.button`
  padding: 10px;
  border: 1px solid #000;
  background-color: #fff;
  color: #000;
  font-size: 16px;
  font-family: 'DM Sans', sans-serif;
  cursor: pointer;
  border-style: none none solid;

  &:focus {
    outline: none;
  }

  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
`;

const VersionChooser = ({filterTypes, changeFunction, filterName, initialValue, versionMapping }) => {
  const [fromVersion, setFromVersion] = useState(filterTypes[filterTypes.length - 1]);
  const [toVersion, setToVersion] = useState(filterTypes[0]);
  const majorVersions = Object.keys(versionMapping).map(key => parseInt(key, 10));

  const arraysAreEqual = (array1, array2) => {
    return array1.length === array2.length && array1.every((value, index) => value === array2[index]);
  };

  const handleFromTo = useCallback(() => {
    let substringResult=[]
    if (filterTypes) {
      substringResult = filterTypes.slice(filterTypes.indexOf(toVersion),filterTypes.indexOf(fromVersion)+1)
    } else {
      console.log('No filter types available');
    }

    if (!arraysAreEqual(substringResult, initialValue)) {
      changeFunction(filterName, substringResult);
    } else {
      console.log("filterTypes.versions array has not changed");
    }

  }, [fromVersion, toVersion, filterTypes, initialValue, changeFunction, filterName]);;

  useEffect(() => {
    if (initialValue.length > 0) {
      setFromVersion(initialValue[initialValue.length - 1])
      setToVersion(initialValue[0])
    }
  }, [initialValue]);

  useEffect(() => {
    setFromVersion(filterTypes[filterTypes.length - 1])
    setToVersion(filterTypes[0])
  }, [filterTypes]);

  useEffect(() => {
    //console.log("To Version updated: ", toVersion);
    handleFromTo();
  }, [fromVersion, toVersion, handleFromTo]);



  const handleAllVersions = () => {
    setFromVersion(filterTypes[0]);
    setToVersion(filterTypes[filterTypes.length])
    // Handle all versions logic here
    changeFunction(filterName, filterTypes)
  };





  // Generate dropdown options
  const dropdownOptions = majorVersions.map((majorVersion, index) => {
    const startIndex = majorVersion;
    const endIndex = majorVersions[index + 1] || filterTypes.length;

    return {
      label: versionMapping[majorVersion],
      values: filterTypes.slice(startIndex, endIndex),
    };
  });


  // Filter versions for the "to" dropdown to only include versions greater than the "from" version
  const handleDropdownChangeFrom = (e) => {
    setFromVersion(e.target.value);
  }
  const handleDropdownChangeTo = (e) => {
    setToVersion(e.target.value);
  }


  return (
    <Wrapper>
      <h4>Select Versions</h4>
      <Row>
        <Dropdown options={dropdownOptions} value={fromVersion} changeFunction={handleDropdownChangeFrom}/>
        <Dropdown options={dropdownOptions} value={toVersion} changeFunction={handleDropdownChangeTo}/>
      </Row>
      <Row>
        <Button onClick={handleAllVersions}>Reset</Button>
      </Row>
    </Wrapper>
  );
};

VersionChooser.propTypes = {
  filterTypes: PropTypes.array.isRequired,
  changeFunction: PropTypes.func,
};
export default VersionChooser;
